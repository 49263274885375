
export default function wb() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 238.049 220.327"
      xmlSpace="preserve"
    >
      <g transform="translate(-477.455 -9.335)">
        <path
          fill="#fff"
          stroke="#20201e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.152"
          d="M146.62 115.065c-1.965-1.07-19.662-18.46-36.077-18.46-8.532 0-15.223 2.576-21.124 4.846-5.983 2.304-11.633 4.481-19.812 4.481-9.532 0-17.445-2.908-24.431-5.474-5.63-2.067-10.495-3.854-15.471-3.854-4.974 0-9.836 1.787-15.468 3.854-6.982 2.566-14.896 5.474-24.43 5.474-8.178 0-13.83-2.177-19.813-4.48-5.9-2.271-12.592-4.847-21.126-4.847-16.412 0-34.11 17.389-36.073 18.46 0 0 7.532-4.469 13.794-4.469 6.261 0 12.407 4.662 22.28 4.662 5.068 0 9.196 1.59 14.425 3.604 6.624 2.55 14.867 5.724 26.514 5.724 12.85 0 23.261-3.822 30.862-6.613 3.461-1.274 7.38-2.715 9.036-2.715 1.662 0 5.58 1.441 9.039 2.715 7.601 2.791 18.01 6.613 30.863 6.613 11.645 0 19.89-3.174 26.515-5.724 5.224-2.014 9.355-3.604 14.421-3.604 9.875 0 13.947-4.662 22.283-4.662 8.4 0 13.874 4.468 13.874 4.468z"
        ></path>
        <path
          fill="#fff"
          stroke="#20201e"
          strokeWidth="4.152"
          d="M47.8-74.003a19.53 19.53 0 00-2.058-.55c3.35-1.244 5.744-4.457 5.744-8.243a8.792 8.792 0 00-8.793-8.793 8.793 8.793 0 00-8.794 8.793c0 3.376 1.901 6.299 4.693 7.777-25.24 1.639-63.555 34.887-64.775 91.18-.486 22.366 2.35 40.675 10.162 53.974l84.45-.602c12.346-15.577 23.7-35.293 23.7-61.145 0-34.393-25.106-76.103-44.328-82.415z"
        ></path>
        <path
          fill="#fff"
          stroke="#20201e"
          strokeWidth="4.152"
          d="M73.096-10.782C63.81-41.078 39.3-70.737 17.686-74.654a8.792 8.792 0 00-3.319-16.933 8.788 8.788 0 00-3.956 16.64 21.309 21.309 0 00-4.387.946c-19.174 6.31-43.73 48.708-43.73 82.393 0 24.846 10.066 46.25 21.684 61.745l84.451-.602c7.991-13.34 17.775-37.502 4.656-80.323z"
        ></path>
        <path
          fill="#fff"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.189"
          d="M68.156 104.777c-10.612-4.868-25.645-8.334-40.732-8.334-15.095 0-29.225 3.073-41.9 8.646 12.675 5.57 26.57 8.81 41.66 8.81 15.102 0 28.315-3.555 40.994-9.124z"
        ></path>
        <path
          fill="#fff"
          stroke="#20201e"
          strokeLinejoin="bevel"
          strokeWidth="4.152"
          d="M63.752 75.424l-.008-.01c-10.282-4.42-22.11-8.126-37.413-8.126-15.317 0-27.13 3.709-37.414 8.136 0 0-2.304 14.969-2.527 18.348-.347 5.182-.868 11.312-.868 11.312 12.675-5.573 26.805-8.645 41.9-8.645 14.798 0 28.238 2.96 40.732 8.334-.448-4.148-.967-8.292-2.003-14.511-.566-3.303-2.422-14.881-2.422-14.881z"
        ></path>
        <path
          fill="#fff"
          stroke="#20201e"
          strokeLinejoin="bevel"
          strokeWidth="4.152"
          d="M-14.492 105.092c12.675-5.574 26.805-8.646 41.9-8.646 14.798 0 28.238 2.96 40.732 8.334-.32-3.47-1.089-5.633-1.573-10.062-12.555-4.398-25.284-6.642-39.354-6.642-14.23 0-28.33 2.584-41.006 7.077-.063 3.901-.724 9.942-.724 9.942z"
        ></path>
        <path
          fill="#fff"
          stroke="#20201e"
          strokeWidth="4.152"
          d="M-11.094 75.424C-.81 71 11.012 67.212 26.332 67.114c13.044-.087 27.133 3.88 37.416 8.304.564-.566 2.953-1.29 4.683-5.89-8.647-9.361-21.504-14.251-42.098-14.251-21.975 0-33.707 5.48-42.353 14.853 1.7 4.593 4.342 4.72 4.908 5.286z"
        ></path>
        <path
          fill="none"
          stroke="#000"
          strokeWidth="3.189"
          d="M26.345 55.289L26.345 67.118"
        ></path>
        <path
          fill="#fff"
          stroke="#000"
          strokeWidth="2.422"
          d="M23.3873 -3.8573L23.3873 -22.5262 27.5087 -26.6476 19.203 -34.947 10.8973 -26.6476 15.0501 -22.4822 15.0501 -3.8573 -5.7455 -3.8573 -9.8669 -7.9787 -18.1726 0.327 -9.8669 8.6326 -5.714 4.4798 15.0501 4.4798 15.0501 27.289 10.8973 31.4418 19.203 39.7475 27.5087 31.4418 23.3873 27.3204 23.3873 4.4798 44.0571 4.4798 48.2099 8.6326 56.5156 0.327 48.2099 -7.9787 44.0886 -3.8573z"
        ></path>
      </g>
    </svg>
  );
}
