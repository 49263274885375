
export default function wq() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 223.667 200.734"
      xmlSpace="preserve"
    >
      <g transform="translate(-963.233 -18.974)">
        <path
          fill="#fff"
          stroke="#20201e"
          strokeLinejoin="bevel"
          strokeWidth="3.495"
          d="M572.569 100.593c-19.086-8.924-40.003-13.61-63.095-13.61s-45.477 5.117-65.19 14.042c19.085 8.916 40.735 13.936 63.83 13.936 23.125.031 44.77-5.443 64.464-14.346z"
        ></path>
        <path
          fill="#fff"
          stroke="#20201e"
          strokeLinejoin="bevel"
          strokeWidth="3.495"
          d="M571.153 60.543l-.012-.021c-17.156-7.073-37.118-11.376-63.035-11.376-25.95 0-46.012 4.306-63.671 11.397 0 0 1.926 16.371 2.006 21.785.095 6.097-1.61 18.59-1.61 18.59 19.712-8.924 41.56-13.942 64.652-13.942 22.65 0 45.669 4.649 63.095 13.61-2.863-5.726-1.416-40.019-1.416-40.019z"
        ></path>
        <path
          fill="#fff"
          stroke="#20201e"
          strokeLinejoin="bevel"
          strokeWidth="3.495"
          d="M444.9 100.813c18.555-8.824 41.491-13.838 64.584-13.838 22.649 0 41.663 3.194 63.094 13.61-.863-2.408-1.44-9.886-1.628-12.599-18.969-7.047-39.89-11.047-61.421-11.047-21.77 0-43.674 4.219-63.325 11.41-.409 2.8-1.29 12.427-1.29 12.427z"
        ></path>
        <path
          fill="#fff"
          stroke="#20201e"
          strokeWidth="3.495"
          d="M613.72-43.828c-4.587-2.68-10.48-1.131-13.16 3.462-2.3 3.936-1.48 8.818 1.688 11.824-13.107 15.887-37.03 44.873-42.803 51.904a83.754 83.754 0 00-5.282-2.147c.52-5.392 4.308-46.537 6.317-68.421 4.366.1 8.394-2.792 9.534-7.199 1.33-5.148-1.758-10.399-6.907-11.73-5.14-1.332-10.39 1.759-11.724 6.903-1.143 4.41.974 8.892 4.842 10.921-9.478 21.513-27.87 63.262-27.915 63.435-.01.041-.003.084-.012.127a144.564 144.564 0 00-5.14-.52c-.348-2.314-8.234-50.71-12.345-75.917 4.726-1.106 8.243-5.335 8.243-10.399 0-5.902-4.784-10.693-10.689-10.693s-10.691 4.791-10.691 10.693c0 5.063 3.527 9.292 8.249 10.399-4.093 25.093-11.93 73.162-12.342 75.877a146.26 146.26 0 00-5.36.527c-.013-.062-.01-.114-.028-.17-.043-.173-18.438-41.927-27.91-63.437 3.862-2.028 5.976-6.509 4.836-10.92-1.33-5.145-6.584-8.236-11.727-6.904-5.143 1.332-8.234 6.582-6.9 11.725 1.142 4.414 5.164 7.302 9.53 7.2 2.01 21.86 5.785 62.92 6.317 68.396a84.215 84.215 0 00-5.186 2.09 63994.541 63994.541 0 00-42.67-51.738c3.173-3.006 3.99-7.888 1.695-11.824-2.676-4.593-8.572-6.142-13.162-3.462-4.587 2.677-6.139 8.57-3.462 13.163 2.3 3.933 6.958 5.624 11.134 4.34 7.825 20.38 33.084 85.973 34.05 86.88 17.411-7.087 37.432-11.398 63.375-11.398 25.93 0 45.945 4.306 63.359 11.39.959-.907 26.551-65.96 34.579-86.872 4.178 1.284 8.833-.407 11.13-4.34 2.58-4.59 1.039-10.483-3.554-13.16z"
        ></path>
        <path d="M517.4178 63.6578L508.4514 59.1903 499.5166 63.6578 508.4514 68.1252z"></path>
        <path d="M480.6085 66.5522L471.0759 63.5948 462.959 69.4466 472.4917 72.4039z"></path>
        <path d="M536.3259 66.6151L545.8585 63.6263 553.9753 69.478 544.4427 72.4668z"></path>
      </g>
    </svg>
  );
}

