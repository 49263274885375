
export default function wp() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 132.17 198.797"
      xmlSpace="preserve"
    >
      <g transform="translate(-1501.976 -33.586)">
        <path
          fill="#fff"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="3.495"
          d="M1026.33 99.366c-11.798-23.424-10.606-65.143-9.62-81.305 18.658-1.28 32.077-4.814 32.077-8.979 0-4.087-12.916-7.571-31.033-8.906 11.57-5.962 19.487-18.02 19.487-31.933 0-19.832-16.071-35.91-35.906-35.91-19.835 0-35.906 16.078-35.906 35.91 0 13.913 7.914 25.97 19.488 31.933-18.115 1.335-31.037 4.819-31.037 8.906 0 4.165 13.426 7.7 32.08 8.98.99 16.162 2.182 57.881-9.616 81.304-23.122 4.648-39.348 15.558-39.348 28.27h128.675c0-12.71-16.202-23.627-39.326-28.283z"
        ></path>
      </g>
    </svg>
  );
}

