
export default function bnr() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 214.771 202.387"
      xmlSpace="preserve"
    >
      <g strokeWidth="2.684" transform="translate(-241.967 -290.398)">
        <path
          fill="#20201e"
          stroke="#20201e"
          d="M-164.623 212.185s-3.49-18.755-3.49-21.375c0-2.615-2.615-3.05-5.233.44-2.617 3.49-14.392 20.498-14.392 20.498l23.092.41z"
        ></path>
        <path
          fill="#20201e"
          stroke="#20201e"
          d="M-321.1 356.055c1.184-8.458 2.8-16.52 4.768-24.188l6.321-2.523-4.221-5.044c2.406-8.111 5.2-15.743 8.27-22.919l5.978-1.693-2.954-5.055a209.024 209.024 0 0110.246-19.12l5.575-1.12-1.998-4.605a213.662 213.662 0 0112.237-16.993l5.9-.647-1.576-4.596a209.095 209.095 0 0113.772-14.696l5.905-.332-1.03-4.23c10.528-9.527 17.968-14.163 17.968-14.163l13.375 4.07s6.396-3.49 11.63-6.978c0 0 3.488-11.63 4.65-18.029 1.165-6.395 4.652-3.487 5.815-1.743 1.166 1.743 13.231 15.12 13.231 15.12s7.217-.99 12.356 0c7.558 1.455 11.05 6.543 19.773 14.83 6.362 6.043 10.178 11.342 12.357 17.01 2.18 5.67-.433 5.67.435 10.901.876 5.236 24.427 44.486 27.041 49.284 2.623 4.796 8.938 19.472-3.487 28.785-3.487 2.62-6.11 3.926-8.286 4.799 0 0-3.49-.873-4.796-2.615a5.606 5.606 0 01-.41-.608c-.043-.123-.065-.123 0 0 .134.34.438 1.616-.9 3.223-2.18 2.62-4.982 2.424-6.544 2.62-2.325.29-6.54-1.748-10.03-6.98-3.492-5.238-5.672-6.98-8.288-10.034-2.615-3.051-17.05-9.807-22.682-19.19-1.307-2.175-4.36-1.306-6.54.438-2.179 1.746-14.393 2.183-19.625-6.543 0 0-2.474 22.941 7.851 38.817 17.007 26.167 45.791 32.706 54.519 57.136H-323.42c0-8.443.444-16.542 1.25-24.337l5.946-3.143-4.978-4.907z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-228.96 224.014s-76.177 56.995-76.177 159.919"
        ></path>
        <path
          fill="none"
          stroke="#20201e"
          d="M-132.25 328.275c-.063-.094-.063-.094 0 0z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-213.262 263.56c-1.451 16.28 6.11 27.91 6.399 28.928 0 0-18.32-9.446-20.066-21.954"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-206.875 292.473s-12.213 5.963-21.226 2.183"
        ></path>
        <path
          fill="none"
          stroke="#20201e"
          d="M-131.84 328.905s-8.144-10.474-10.473-15.27"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-182.02 232.288s13.41.068 17.882 7.412c6.106 10.035-.871 11.34-.871 11.34s-6.98 2.185-11.777-4.359c-5.107-6.965-.872-11.34-.872-11.34l-4.342-3.052z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-117.023 306.221c-4.799-1.306-7.851-.875-10.028.44 0 0 2.177-6.546-1.743-12.216-3.93-5.67-10.473-6.105-16.14-17.008"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-244.376 238.423L-252.651 233.169"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-261.384 257.174L-269.671 253.241"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-276.646 278.913L-286.241 274.981"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-288.865 303.83L-298.889 300.338"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-298.455 333.058L-308.925 330.006"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M-304.127 362.694L-315.028 361.404"
        ></path>
      </g>
    </svg>
  );
}

