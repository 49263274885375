
export default function wnr() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 215.55 203.185"
      xmlSpace="preserve"
    >
      <g stroke="#20201e" transform="translate(-241.744 -17.901)">
        <path
          fill="#fff"
          strokeWidth="3.495"
          d="M-164.466-59.908s-3.49-18.755-3.49-21.371c0-2.617-2.613-3.054-5.232.436-2.618 3.489-14.393 20.499-14.393 20.499l23.092.434z"
        ></path>
        <path
          fill="#fff"
          strokeWidth="3.495"
          d="M-320.95 83.95a219.476 219.476 0 014.77-24.187l6.32-2.522-4.218-5.048a209.692 209.692 0 018.268-22.92l5.98-1.69-2.957-5.057a209.553 209.553 0 0110.245-19.12l5.576-1.12-2-4.603a214.43 214.43 0 0112.237-16.994l5.902-.646-1.576-4.596a209.916 209.916 0 0113.775-14.699l5.904-.331-1.033-4.23c10.53-9.527 17.97-14.163 17.97-14.163l13.375 4.072s6.395-3.49 11.63-6.979c0 0 3.487-11.63 4.649-18.027 1.166-6.397 4.653-3.49 5.815-1.745 1.166 1.745 13.232 15.118 13.232 15.118s7.218-.987 12.358 0c7.557 1.457 11.047 6.544 19.774 14.83 6.358 6.045 10.174 11.343 12.354 17.01 2.18 5.67-.435 5.67.435 10.904.877 5.233 24.427 44.485 27.04 49.283 2.622 4.798 8.94 19.47-3.486 28.785-3.487 2.617-6.108 3.93-8.285 4.797 0 0-3.492-.868-4.797-2.613a5.295 5.295 0 01-.41-.608c-.042-.117-.065-.124 0 0 .133.34.437 1.615-.9 3.221-2.182 2.62-4.982 2.424-6.542 2.62-2.327.291-6.542-1.743-10.033-6.98-3.49-5.233-5.67-6.98-8.289-10.032-2.613-3.053-17.049-9.807-22.68-19.19-1.307-2.177-4.36-1.31-6.54.438-2.18 1.745-14.394 2.182-19.624-6.545 0 0-2.475 22.945 7.85 38.82 17.007 26.169 45.794 32.707 54.521 57.136h-178.896c0-8.441.446-16.546 1.25-24.342l5.947-3.137-4.98-4.845z"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-228.803-48.079s-76.173 56.995-76.173 159.922"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-132.093 56.862c-.062-.126-.062-.126 0 0z"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-213.104-8.545c-1.453 16.279 6.108 27.91 6.398 28.926 0 0-18.322-9.447-20.064-21.953"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-206.718 20.399s-12.215 5.965-21.227 2.184"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-131.684 57.46s-8.143-10.469-10.472-15.266"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-181.864-39.789s13.411.063 17.885 7.412c6.103 10.033-.873 11.34-.873 11.34s-6.977 2.183-11.776-4.359c-5.108-6.967-.873-11.34-.873-11.34l-4.341-3.055z"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-116.865 34.777c-4.803-1.308-7.853-.87-10.03.437 0 0 2.177-6.542-1.745-12.214-3.93-5.67-10.47-6.107-16.14-17.01"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-244.219 -33.685L-252.493 -38.917"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-261.23 -14.932L-269.52 -18.855"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-276.492 6.839L-286.087 2.907"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-288.708 31.725L-298.738 28.233"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-298.3 60.952L-308.771 57.9"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M-303.973 90.62L-314.874 89.298"
        ></path>
      </g>
    </svg>
  );
}

