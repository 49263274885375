
export default function bq() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 222.914 199.585"
      xmlSpace="preserve"
    >
      <g transform="translate(-964.585 -289.82)">
        <path
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="2.684"
          d="M618.124 239.776c2.676-4.593 1.13-10.486-3.462-13.164-4.584-2.679-10.477-1.13-13.156 3.461-2.298 3.936-1.481 8.82 1.687 11.823-13.107 15.89-37.024 44.876-42.803 51.904a87.282 87.282 0 00-5.278-2.15c.516-5.388 4.304-46.534 6.317-68.415 4.365.1 8.39-2.792 9.533-7.202 1.33-5.146-1.76-10.395-6.903-11.729-5.15-1.33-10.397 1.76-11.73 6.905-1.14 4.408.973 8.889 4.842 10.924-9.479 21.507-27.87 63.259-27.916 63.432-.009.041-.003.084-.012.126a138.88 138.88 0 00-5.134-.52c-.35-2.313-8.237-50.711-12.35-75.918 4.725-1.104 8.249-5.334 8.249-10.397 0-5.906-4.787-10.695-10.695-10.695-5.902 0-10.692 4.789-10.692 10.695 0 5.063 3.527 9.292 8.252 10.397-4.092 25.092-11.93 73.162-12.341 75.877a149.95 149.95 0 00-5.365.524c-.012-.06-.009-.11-.027-.167-.043-.172-18.438-41.928-27.91-63.435 3.865-2.03 5.98-6.512 4.84-10.922-1.334-5.143-6.585-8.234-11.73-6.902-5.14 1.332-8.235 6.583-6.901 11.726 1.143 4.411 5.164 7.301 9.53 7.2 2.01 21.858 5.786 62.918 6.317 68.396a83.78 83.78 0 00-5.186 2.084c-6.007-7.301-29.658-35.969-42.67-51.734 3.173-3.003 3.994-7.887 1.696-11.822-2.676-4.592-8.572-6.14-13.162-3.461-4.587 2.677-6.136 8.57-3.457 13.163 2.295 3.931 6.953 5.624 11.128 4.338 7.826 20.38 33.085 85.977 34.05 86.88h.01c.018.113 2.5 16.384 2.774 21.78.273 5.367-2.062 18.498-1.893 18.575v.012c.003 0 .006-.006.01-.006 18.731 8.772 40.065 13.703 62.943 13.703 23.092 0 64.775-14.12 64.58-14.213-2.725-5.751-1.696-39.857-1.696-39.857.965-.913 26.554-65.958 34.585-86.873 4.121 1.29 8.778-.41 11.074-4.342"
        ></path>
        <g stroke="#fff" strokeLinecap="round" strokeWidth="2.912">
          <path d="M451.224 367.444c19.697-8.117 38.134-11.601 60.726-11.601 21.697 0 39.065 3.592 57.224 11.116"></path>
          <path d="M565.427 329.408c-16.022-6.157-35.651-9.78-56.856-9.78-21.396 0-41.182 3.688-57.28 9.943"></path>
        </g>
      </g>
    </svg>
  );
}

