
export default function bnl() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 214.688 202.35"
      xmlSpace="preserve"
    >
      <g strokeWidth="2.684" transform="translate(-1225.682 -283.067)">
        <path
          fill="#000"
          stroke="#000"
          d="M713.577 204.854s3.49-18.756 3.49-21.374c0-2.615 2.614-3.053 5.232.438 2.618 3.49 14.394 20.498 14.394 20.498l-23.124.41z"
        ></path>
        <path
          fill="#000"
          stroke="#000"
          d="M870.032 348.725a219.907 219.907 0 00-4.769-24.182l-6.32-2.526 4.222-5.048a210.4 210.4 0 00-8.27-22.918l-5.98-1.693 2.99-5.097a208.825 208.825 0 00-10.25-19.12l-5.573-1.119 2-4.604a213.968 213.968 0 00-12.24-16.993l-5.9-.647 1.577-4.596a209.406 209.406 0 00-13.77-14.696l-5.909-.332 1.032-4.23c-10.525-9.528-17.97-14.163-17.97-14.163l-13.374 4.071s-6.396-3.492-11.629-6.979c0 0-3.49-11.63-4.648-18.028-1.168-6.394-4.658-3.486-5.816-1.746-1.167 1.746-13.232 15.121-13.232 15.121s-7.218-.99-12.354 0c-7.561 1.455-11.052 6.544-19.774 14.832-6.366 6.04-10.182 11.34-12.357 17.007-2.181 5.67.433 5.67-.436 10.902-.876 5.237-24.428 44.49-27.04 49.287-2.62 4.798-8.94 19.468 3.487 28.787 3.484 2.617 6.105 3.929 8.283 4.795 0 0 3.49-.866 4.8-2.611a6.45 6.45 0 00.41-.611c.04-.12.062-.123 0 0-.134.34-.442 1.616.898 3.222 2.181 2.618 4.984 2.428 6.544 2.618 2.323.295 6.538-1.742 10.032-6.977 3.487-5.233 5.668-6.98 8.286-10.032 2.614-3.054 17.051-9.808 22.683-19.191 1.306-2.177 4.36-1.308 6.538.438 2.181 1.745 14.39 2.184 19.626-6.543 0 0 2.473 22.943-7.853 38.82-17.005 26.164-45.794 32.706-54.518 57.133H872.28c0-8.443-.445-16.548-1.25-24.34l-5.948-3.14 5.034-4.876z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M777.914 216.684s76.176 56.991 76.176 159.922"
        ></path>
        <path
          fill="none"
          stroke="#000"
          d="M681.203 320.945c.063-.094.032-.094 0 0z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M762.215 256.23c1.45 16.279-6.108 27.91-6.397 28.927 0 0 18.32-9.447 20.066-21.953"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M755.797 285.142s12.213 5.964 21.227 2.183"
        ></path>
        <path
          fill="none"
          stroke="#000"
          d="M680.794 321.574s8.142-10.47 10.47-15.266"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M730.943 224.958s-13.41.064-17.884 7.41c-6.105 10.035.875 11.342.875 11.342s6.974 2.183 11.773-4.36c5.11-6.966.87-11.341.87-11.341l4.341-3.052z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M665.976 298.89c4.796-1.306 7.847-.872 10.025.44 0 0-2.178-6.544 1.745-12.215 3.93-5.67 10.474-6.104 16.136-17.006"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M793.299 231.061L801.604 225.839"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M810.319 249.812L818.625 245.911"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M825.577 271.583L835.173 267.65"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M837.816 296.5L847.82 293.008"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M847.38 325.727L857.856 322.675"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          d="M853.074 355.363L863.96 354.073"
        ></path>
      </g>
    </svg>
  );
}
