
export default function wr() {
  return (
    <>
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 162.251 211.197"
      xmlSpace="preserve"
      >
      <g
        fill="#fff"
        stroke="#20201e"
        strokeWidth="3.495"
        transform="translate(-30.651 -13.743)"
        >
        <path d="M-380.517 120.193a4.942 4.942 0 004.945-4.947v-11.622a4.94 4.94 0 00-4.945-4.945h-148.867a4.942 4.942 0 00-4.945 4.945v11.622a4.943 4.943 0 004.945 4.947z"></path>
        <path d="M-526.332 80.364H-383.56600000000003V98.683H-526.332z"></path>
        <path
          strokeLinejoin="bevel"
          d="M-511.6526 63.9094L-526.3322 80.3635 -383.5658 80.3635 -398.2454 63.9094z"
          ></path>
        <path
          strokeLinejoin="round"
          d="M-511.6526 -33.0436L-530.8688 -51.3664 -379.092 -51.3664 -398.2454 -33.0436z"
          ></path>
        <path d="M-511.652 -33.044H-398.39599999999996V63.919H-511.652z"></path>
        <path
          strokeLinecap="square"
          d="M-383.758-87.509h-26.416v16.987l-14.824 4.91-13.669-4.91v-16.987h-32.562v16.987l-13.669 4.91-14.825-4.91v-16.987h-26.416c-2.612 0-4.729 2.212-4.729 4.942v31.254h151.843v-31.254c-.003-2.73-2.12-4.942-4.731-4.942z"
          ></path>
      </g>
    </svg>
  </>
  );
}