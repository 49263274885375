
export default function bb() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 233.906 218.255"
      xmlSpace="preserve"
    >
      <g transform="translate(-482.796 -279.45)">
        <path
          fill="#20201e"
          d="M149.892 385.188c-1.965-1.072-19.662-18.462-36.074-18.462-8.533 0-15.225 2.575-21.124 4.848-5.984 2.305-11.635 4.48-19.813 4.48-9.532 0-17.445-2.91-24.43-5.475-5.631-2.065-10.495-3.853-15.472-3.853-4.974 0-9.838 1.788-15.47 3.853-6.981 2.565-14.896 5.475-24.428 5.475-8.18 0-13.83-2.175-19.813-4.48-5.9-2.273-12.59-4.848-21.123-4.848-16.415 0-34.113 17.39-36.076 18.462 0 0 7.145-4.47 13.793-4.47 6.65 0 12.41 4.664 22.284 4.664 5.065 0 9.193 1.588 14.422 3.6 6.624 2.553 14.866 5.724 26.514 5.724 12.852 0 23.261-3.822 30.862-6.612 3.46-1.272 7.38-2.713 9.036-2.713 1.662 0 5.58 1.441 9.039 2.713 7.602 2.79 18.01 6.612 30.863 6.612 11.646 0 19.89-3.17 26.515-5.724 5.225-2.012 9.355-3.6 14.423-3.6 9.873 0 17.356-4.664 22.28-4.664 4.925 0 13.875 4.467 13.875 4.467z"
        ></path>
        <path
          fill="#fff"
          stroke="#20201e"
          strokeWidth="4.152"
          d="M95.684 278.504c0-29.43-25.111-76.082-44.331-82.39a19.09 19.09 0 00-2.063-.551c3.351-1.243 5.748-4.458 5.748-8.243a8.794 8.794 0 00-8.793-8.793c-4.854 0-8.798 3.936-8.798 8.793 0 3.375 1.908 6.298 4.697 7.776-3.94.255-8.197 1.28-12.583 3.046-2.798-1.272-5.58-2.183-8.323-2.682a8.792 8.792 0 00-3.321-16.933 8.792 8.792 0 00-8.793 8.793c0 3.43 1.97 6.398 4.836 7.847a21.376 21.376 0 00-4.388.947c-19.174 6.31-43.727 51.234-43.727 82.39 0 24.846 10.067 46.25 21.686 61.745 1.73 4.593 4.36 4.728 4.928 5.297.002 0 .003-.003.005-.003-.01.077-2.303 14.975-2.527 18.339-.346 5.186-.868 11.312-.868 11.312 12.678 5.577 26.57 8.815 41.66 8.815 15.095 0 28.302-3.552 40.975-9.125-.451-4.145-.969-8.286-2.006-14.508-.54-3.253-2.403-14.839-2.403-14.839.566-.562 3.985-4.851 3.985-4.851 12.333-15.573 24.382-36.338 24.382-62.167z"
        ></path>
        <path
          stroke="#000"
          strokeWidth="1.677"
          d="M-7.224 345.548c10.282-4.43 22.104-8.314 37.423-8.314 15.314 0 27.132 3.88 37.413 8.304.564-.562 1.908-2.003 4.684-5.89-8.65-9.361-21.24-14.504-41.834-14.504-21.973 0-33.968 5.733-42.617 15.106 1.762 4.562 4.405 4.72 4.971 5.286z"
        ></path>
        <path
          fill="#fff"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.189"
          d="M71.365 374.869c-12.68-5.57-25.646-8.332-40.738-8.332-15.093 0-29.22 3.072-41.897 8.642 12.676 5.577 26.568 8.815 41.66 8.815 15.102 0 28.315-3.555 40.994-9.124z"
        ></path>
        <path
          stroke="#000"
          strokeWidth="1.677"
          d="M71.02 340.671c7.984-13.346 18.49-38.518 5.362-81.355-11.015-35.94-43.467-71.01-67.072-63.217-20.936 6.913-43.727 56.419-43.727 82.39 0 24.846 10.063 46.25 21.682 61.745l83.78.409z"
        ></path>
        <circle
          cx="17.661"
          cy="187.299"
          r="8.794"
          stroke="#000"
          strokeWidth="1.677"
        ></circle>
        <path
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="1.677"
          d="M67.023 345.516l-.003-.009c-10.281-4.421-22.108-8.126-37.413-8.126-15.317 0-27.129 3.708-37.413 8.135 0 0-2.305 14.972-2.529 18.345-.345 5.186-.866 11.312-.866 11.312 12.675-5.57 26.806-8.642 41.893-8.642 14.803 0 28.243 2.962 40.739 8.332-.45-4.145-.97-8.286-2.005-14.508-.566-3.272-2.422-14.88-2.422-14.88z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1.677"
          d="M66.646 365.588a121.799 121.799 0 00-36.12-5.484c-12.769 0-25.819 2.08-37.524 5.754"
        ></path>
        <g fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.677">
          <path d="M-4.55 344.163c9.523-3.834 20.442-6.953 34.166-6.953 13.798 0 24.756 3.153 34.315 7.011"></path>
          <path d="M70.138 337.4c-12.088-9.67-21.761-12.26-39.663-12.26-14.993 0-30.463 3.97-37.875 10.76"></path>
        </g>
        <path
          fill="#fff"
          stroke="#000"
          strokeWidth="1.677"
          d="M14.2007 243.4568L18.3535 247.6096 18.3535 266.266 -2.4736 266.266 -6.5949 262.1446 -14.9006 270.4503 -6.5949 278.756 -2.4106 274.6031 18.3535 274.6031 18.3535 297.3808 14.2007 301.5336 22.5064 309.8393 30.8121 301.5336 26.6907 297.4123 26.6907 274.6031 47.329 274.6031 51.4818 278.756 59.7875 270.4503 51.4818 262.1446 47.3919 266.266 26.6907 266.266 26.6907 247.5782 30.8121 243.4568 22.5064 235.1512z"
        ></path>
      </g>
    </svg>
  );
}

