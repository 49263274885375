
export default function bp() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 131.159 197.787"
      xmlSpace="preserve"
    >
      <g transform="translate(-1502.481 -297.106)">
        <path
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="2.484"
          d="M1026.33 362.379c-11.798-23.42-10.606-65.14-9.62-81.304 18.658-1.28 32.077-4.813 32.077-8.978 0-4.087-12.916-7.57-31.033-8.906 11.57-5.964 19.487-18.018 19.487-31.93 0-19.835-16.071-35.913-35.906-35.913-19.835 0-35.906 16.078-35.906 35.913 0 13.912 7.92 25.966 19.488 31.93-18.115 1.336-31.037 4.818-31.037 8.906 0 4.165 13.426 7.698 32.08 8.978.99 16.166 2.182 57.885-9.616 81.304-23.122 4.648-39.348 15.562-39.348 28.272h128.675c0-12.71-16.202-23.627-39.326-28.284z"
        ></path>
      </g>
    </svg>
  );
}


