
export default function bk() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 200.651 193.885"
      xmlSpace="preserve"
    >
      <g transform="translate(-737.476 -296.007)">
        <path
          stroke="#000"
          strokeWidth="0.975"
          d="M289.546 255.255c4.256-2.517 6.053-5.882 6.053-9.586 0-1.085.153-52.175.153-52.175H245.1s-.238 51.53-.238 52.175c0 3.731 2.644 7.12 6.957 9.642-3.05.444-6.067.988-9.05 1.643a84.383 84.383 0 0113.447 10.671c5.908 5.747 10.796 12.112 14.634 18.974 3.837-6.862 8.728-13.227 14.63-18.974a84.904 84.904 0 0113.46-10.677c-3.052-.661-6.198-1.196-9.344-1.668z"
        ></path>
        <path d="M336.14 371.377c-3.134-6.283-3.22-14.499-1.828-20.047 1.426-5.709 14.947-16.511 14.947-16.511 23.82-23.169 29.274-56.196 12.185-73.763-17.095-17.566-50.252-13.015-74.072 10.157-7.112 6.922-12.568 14.721-16.299 22.728-3.726-8.007-9.189-15.806-16.298-22.728-23.816-23.172-56.98-27.723-74.072-10.157-17.087 17.566-11.63 50.595 12.184 73.763 1.075 1.044 12.945 10.802 14.372 16.51 1.424 5.703 1.622 13.9-1.803 20.26h.514c-.075.042-.149.07-.226.113 19.71 9.721 41.89 15.19 65.348 15.19 23.438-.032 45.304-5.82 65.03-15.542"></path>
        <g stroke="#fff" strokeLinecap="round" strokeWidth="3.174">
          <path d="M209.195 370.213c18.97-8.852 39.943-13.764 62.255-13.764 21.433 0 42.004 5.263 60.355 12.738"></path>
          <path d="M340.922 333.466c-19.755-6.704-43.963-10.654-70.12-10.654-26.385 0-50.784 4.024-70.636 10.836"></path>
        </g>
        <path
          fill="none"
          stroke="#fff"
          strokeLinejoin="bevel"
          strokeWidth="1.95"
          d="M291.623 235.277L249.1192 197.1778 291.623 197.1778 249.1192 235.277z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="3.174"
          d="M328.558 356.716c-18.191-6.387-37.545-11.02-57.62-11.02-20.463 0-40.173 4.762-58.66 11.373"
        ></path>
        <path
          fill="#fff"
          stroke="#000"
          strokeWidth="0.997"
          d="M280.9262 335.2912L270.9531 330.2889 260.98 335.2912 270.9531 340.262z"
        ></path>
        <path
          fill="#fff"
          stroke="#000"
          strokeWidth="0.997"
          d="M239.9012 338.5631L229.2674 335.2283 220.2381 341.7722 230.8719 345.0755z"
        ></path>
        <path
          fill="#fff"
          stroke="#000"
          strokeWidth="0.997"
          d="M302.005 338.5631L312.6388 335.2283 321.6996 341.7722 311.0343 345.0755z"
        ></path>
      </g>
    </svg>
  );
}
