
export default function wnl() {
  return (
    <svg
      width="30"
      height="30"
      x="0"
      y="0"
      version="1.1"
      viewBox="-566.727 -103 215.477 203.185"
      xmlSpace="preserve"
    >
      <g stroke="#20201e" transform="translate(-1214.8 -17.901)">
        <path
          fill="#fff"
          strokeWidth="3.495"
          d="M703.1-59.908s3.49-18.755 3.49-21.371c0-2.617 2.615-3.054 5.233.436 2.617 3.489 14.394 20.499 14.394 20.499l-23.124.434z"
        ></path>
        <path
          fill="#fff"
          strokeWidth="3.495"
          d="M859.587 83.95a219.132 219.132 0 00-4.769-24.187l-6.32-2.522 4.219-5.048a209.36 209.36 0 00-8.268-22.92l-5.982-1.69 2.959-5.057a209.605 209.605 0 00-10.246-19.12l-5.577-1.12 2.004-4.603a214.411 214.411 0 00-12.24-16.994l-5.903-.646 1.576-4.596a209.895 209.895 0 00-13.773-14.699l-5.902-.331 1.03-4.23c-10.53-9.527-17.971-14.163-17.971-14.163l-13.374 4.072s-6.394-3.49-11.629-6.979c0 0-3.49-11.63-4.648-18.027-1.168-6.397-4.655-3.49-5.82-1.745-1.16 1.745-13.229 15.118-13.229 15.118s-7.217-.987-12.36 0c-7.555 1.457-11.045 6.544-19.774 14.83-6.356 6.045-10.172 11.343-12.35 17.01-2.182 5.67.432 5.67-.434 10.904-.879 5.233-24.431 44.485-27.043 49.283-2.62 4.798-8.937 19.47 3.487 28.785 3.484 2.617 6.108 3.93 8.286 4.797 0 0 3.488-.868 4.796-2.613a5.4 5.4 0 00.409-.608c.043-.117.065-.124 0 0-.132.34-.436 1.615.9 3.221 2.182 2.62 4.984 2.424 6.541 2.62 2.326.291 6.541-1.743 10.034-6.98 3.49-5.233 5.672-6.98 8.29-10.032 2.611-3.053 17.048-9.807 22.68-19.19 1.306-2.177 4.36-1.31 6.538.438 2.181 1.745 14.394 2.182 19.626-6.545 0 0 2.473 22.945-7.85 38.82-17.008 26.169-45.794 32.707-54.518 57.136h178.82c0-8.441-.445-16.546-1.25-24.342l-5.945-3.137 5.128-4.845z"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M767.438-48.079s76.176 56.995 76.176 159.922"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M670.727 56.862c.063-.126.063-.126 0 0z"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M751.739-8.545c1.453 16.279-6.108 27.91-6.397 28.926 0 0 18.32-9.447 20.063-21.953"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M745.352 20.399s12.213 5.965 21.227 2.184"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M670.318 57.46s8.142-10.469 10.473-15.266"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M720.498-39.789s-13.41.063-17.881 7.412c-6.105 10.033.87 11.34.87 11.34s6.977 2.183 11.776-4.359c5.109-6.967.872-11.34.872-11.34l4.342-3.055z"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M655.5 34.777c4.799-1.308 7.853-.87 10.031.437 0 0-2.178-6.542 1.742-12.214 3.93-5.67 10.468-6.107 16.14-17.01"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M782.854 -33.685L791.128 -38.917"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M799.874 -14.932L808.148 -18.855"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M815.132 6.839L824.728 2.907"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M827.339 31.725L837.375 28.233"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M836.935 60.952L847.411 57.9"
        ></path>
        <path
          fill="none"
          strokeWidth="2.684"
          d="M842.598 90.62L853.515 89.298"
        ></path>
      </g>
    </svg>
  );
}
